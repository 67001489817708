<template>
  <a-card :bordered="false">
    <div class="card-title">{{ $route.meta.title }}</div>
    <div class="table-operator">
      <SearchForm ref="SearchForm" @success="handleSeachSubmit"/>
    </div>
    <s-table
        ref="table"
        rowKey="address_id"
        :loading="isLoading"
        :columns="columns"
        :data="loadData"
        :pageSize="15"
    >
      <div slot="ysxx" slot-scope="text, item">
        装货地址：<a>{{ item.start_address || '-' }}</a><br>
        卸货地址：<a>{{ item.end_address || '-' }}</a><br>
        公里数：<a>{{ item.kilometers || '-' }}km</a><br>
        吨位：<a>{{ item.tonnage || '-' }}吨</a><br>
        装货品名：<a>{{ item.goods_name || '-' }}</a><br>
      </div>
      <div slot="cbtj" slot-scope="text, item">
        油费：<a><span style="color: red">￥</span>{{ item.refuel_amount || '-' }}</a><br>
        路费：<a><span style="color: red">￥</span>{{ item.travel_amount || '-' }}</a><br>
        人工工资：<a><span style="color: red">￥</span>{{ item.wages || '-' }}</a><br>

        <div style="display:flex">
          <div>其他成本费用：</div>
          <div>
            <div v-for="item2 in item.other_cost"><a>{{ item2.name }}：<span
                style="color: red">￥</span>{{ item2.price || '-' }}</a></div>
          </div>
          <div v-if="item.other_cost.length===0">-</div>
        </div>
        成本总计：<a><span style="color: red">￥</span>{{ item.cost_total || '-' }}</a><br>
      </div>
      <div slot="lrxx" slot-scope="text, item">
        运费：<a><span style="color: red">￥</span>{{ item.freight }}</a><br>
        利润：<a><span style="color: red">￥</span>{{ item.profit }}</a>
      </div>
      <div slot="status" slot-scope="text, item">
        <a-tag color="green" v-if="text===0">启用</a-tag>
        <a-tag color="red" v-else>停用</a-tag>
      </div>
      <div class="actions" slot="action" slot-scope="text, item">
        <a v-action:recalculate @click="handlEafresh(item)">重新计算</a>
        <!--        <a v-action:delete @click="handleDelete(item)">删除</a>-->
      </div>
    </s-table>
    <SaveForm ref="SaveForm" @handleSubmit="handleRefresh"/>
  </a-card>
</template>

<script>
import * as Api from '@/api/data/calculator'
import {STable} from '@/components'
import SaveForm from './modules/SaveForm'
import SearchForm from './modules/SearchForm'

export default {
  name: 'Index',
  components: {
    STable,
    SaveForm,
    SearchForm
  },
  data() {
    return {
      // 查询参数
      queryParam: {},
      // 正在加载
      isLoading: false,
      // 表头
      columns: [
        {
          title: '运输信息',
          dataIndex: 'ysxx',
          scopedSlots: {customRender: 'ysxx'}
        },
        {
          title: '成本统计',
          dataIndex: 'cbtj',
          scopedSlots: {customRender: 'cbtj'}
        },
        {
          title: '利润信息',
          dataIndex: 'lrxx',
          scopedSlots: {customRender: 'lrxx'}
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '180px',
          scopedSlots: {customRender: 'action'}
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },
  created() {

  },
  methods: {
    handlEafresh(item) {
      this.$refs.SearchForm.setData(item)
    },
    /**
     * 新增记录
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑记录
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    /**
     * 删除记录
     */
    handleDelete(item) {
      const app = this
      const modal = this.$confirm({
        title: '您确定要删除该记录吗?',
        content: '删除后不可恢复',
        onOk() {
          return Api.deleted({addressId: item['address_id']})
              .then((result) => {
                app.$message.success(result.message, 1.5)
                app.handleRefresh()
              })
              .finally(result => {
                modal.destroy()
              })
        }
      })
    },

    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...{form: value}
      }
      this.handleRefresh(true)
    },

    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },

    /**
     * 检索查询
     */
    onSearch() {
      this.handleRefresh(true)
    }

  }
}
</script>
